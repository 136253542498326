import React, { Fragment, useState } from "react"
import { Grid, Typography, Container } from "@mui/material"
import "./Merchants.css"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import CustomModal from "../Form/Form"
import merchant1 from "../../assets/new_images/Iphone mockup apace.png"
import external_site from "../../assets/new_images/external_site.mp4"
import thumbnail from "../../assets/new_images/thumbnail.png"

const Merchants = () => {
  let [formState, setFormState] = useState(false)
  let { ref, inView, entry } = useInView()
  return (
    <Fragment>
      {formState ? <CustomModal setFormState={setFormState} /> : null}
      <div className="merchants_container" ref={ref}>
        <Container sx={{ maxWidth: "1800px" }} maxWidth={false}>
          <Grid
            container
            spacing={3}
            className="Grid_container_spread_tablet custom_main_container"
          >
            <Grid item xs={12} md={6}>
              <motion.div
                className="merchants_content"
                initial={inView === false ? { y: 50, opacity: 0 } : {}}
                animate={inView === true ? { y: 0, opacity: 1 } : {}}
                transition={{ bounce: false, type: "spring", stiffness: 30 }}
              >
                <Typography variant="h3">
                  Don’t let your money be in limbo.
                  <br /> Be in control of your refund.
                  <br /> Get it <span>instantly</span>
                </Typography>
                <Typography variant="body1">
                  The process is simple. You'll get an email once you issue a
                  return, create your account with Apace and receive your
                  instant refund. The whole process should take 3 minutes or
                  less.
                </Typography>
              </motion.div>
            </Grid>
            <Grid item xs={12} md={6}>
              <motion.div
                className="merchants_img"
                initial={inView === false ? { y: 120, opacity: 0 } : {}}
                animate={inView === true ? { y: 0, opacity: 1 } : {}}
                transition={{ bounce: false, type: "spring", stiffness: 30 }}
              >
                <img src={merchant1} alt="merchants" />
                {/* <Player playsInline loop autoPlay={true} src={external_site}>
                <ControlBar disableCompletely className="my-class" />
              </Player> */}
                <video
                  className="VideoTag"
                  autoPlay
                  loop
                  muted
                  playsInline
                  poster={thumbnail}
                >
                  <source src={external_site} type="video/mp4" />
                </video>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Fragment>
  )
}

export default Merchants
