import React from "react"
import { Grid, Typography } from "@mui/material"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import logo from "../../assets/new_images/logo.png"
import appStore from "../../assets/new_images/appstore.png"
import googleStore from "../../assets/new_images/googlestore.png"
import fb from "../../assets/new_images/facebook.png"
import insta from "../../assets/new_images/insta.svg"
import linkedin from "../../assets/new_images/lindedin.svg"
import twitter from "../../assets/new_images/twitter.svg"
import "./Footer.css"

const Footer = () => {
  let { ref, inView, entry } = useInView()
  return (
    <div className="footer_container custom_main_container" ref={ref}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9} lg={9} xl={10}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <motion.div
                className="section_1"
                initial={inView === false ? { y: 30, opacity: 0 } : {}}
                animate={inView === true ? { y: 0, opacity: 1 } : {}}
                transition={{ bounce: false, type: "spring", stiffness: 30 }}
              >
                <img src={logo} alt="logo" />
                <small>Call Us:</small>
                <span className="section1_num">1-888-718-1004</span>
                <small>Email Us:</small>
                <span>info@apacerefunds.com</span>
              </motion.div>
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <motion.div
                className="section_2"
                initial={inView === false ? { y: 30, opacity: 0 } : {}}
                animate={inView === true ? { y: 0, opacity: 1 } : {}}
                transition={{ bounce: false, type: "spring", stiffness: 30 }}
              >
                <Typography variant="h5">Merchants</Typography>
                <small>Partner with Apace</small>
                <small>Transparency</small>
                <small>Return Reimbursement</small>
                <small>Integrate</small>
                <small>Merchant sign in</small>
                <small>Merchant sign up</small>
              </motion.div>
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <motion.div
                className="section_2"
                initial={inView === false ? { y: 30, opacity: 0 } : {}}
                animate={inView === true ? { y: 0, opacity: 1 } : {}}
                transition={{ bounce: false, type: "spring", stiffness: 30 }}
              >
                <Typography variant="h5">Consumers</Typography>
                <small>How it works</small>
                <small>ApaceBlue</small>
                <small>Why apace?</small>
                <small>Your instant refund</small>
                <small>Consumer login</small>
              </motion.div>
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <motion.div
                className="section_2"
                initial={inView === false ? { y: 30, opacity: 0 } : {}}
                animate={inView === true ? { y: 0, opacity: 1 } : {}}
                transition={{ bounce: false, type: "spring", stiffness: 30 }}
              >
                <Typography variant="h5">Support</Typography>
                <small>Apace for retailers</small>
                <small>Developer support</small>
                <small>Frequently Asked Questions</small>
                <small>Contact</small>
              </motion.div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3} lg={3} xl={2}>
          <motion.div
            className="section_3"
            initial={inView === false ? { y: 30, opacity: 0 } : {}}
            animate={inView === true ? { y: 0, opacity: 1 } : {}}
            transition={{ bounce: false, type: "spring", stiffness: 30 }}
          >
            <div className="line"></div>
            <div className="connected">
              <Typography variant="h5">Stay Connected</Typography>
              <div className="box_container">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/apace-refunds"
                >
                  <img src={linkedin} alt="linkedin" />
                </a>
                <img src={fb} alt="facebook" />
                <img src={twitter} alt="twitter" />
              </div>
            </div>
            <div className="works">
              <Typography variant="h5">In the Works</Typography>
              <div className="box_container">
                <img src={appStore} alt="instagram" />
                <img src={googleStore} alt="twitter" />
              </div>
            </div>
          </motion.div>
        </Grid>
      </Grid>
      <motion.div
        className="footer_end_container"
        initial={inView === false ? { y: 30, opacity: 0 } : {}}
        animate={inView === true ? { y: 0, opacity: 1 } : {}}
        transition={{ bounce: false, type: "spring", stiffness: 30 }}
      >
        <div className="terms">
          <small>Privacy Policy</small>
          <small>Terms of Conditions</small>
          <small>Site by Yudart</small>
        </div>
        <div className="copy_right">
          <small>© 2022 Apace payments Inc.</small>
        </div>
      </motion.div>
    </div>
  )
}

export default Footer
