import React, { Fragment } from "react"
import HeroHeader from "../components/HeroHeader/HeroHeader"
import Navbar from "../components/Navbar/Navbar"
import { ThemeProvider } from "@mui/material/styles"
import { Container } from "@mui/material"
import theme from "../theme"
import "../styles/home.scss"
import Works from "../components/Works/Works"
import Mission from "../components/Mission/Mission"
import Question from "../components/Question/Question"
import Merchants from "../components/Merchants/Merchants"
import Footer from "../components/Footer/Footer"
import Difference from "../components/Difference/Difference"
import { Helmet } from "react-helmet"
import favicon from "../images/favicon.ico"
import MobileMenu from "../components/Layout/MobileMenu"

const Home = () => {
  return (
    <Fragment>
      <Helmet>
        <title>
          Apace for Shoppers | Get Your Refund Instantly In Real-Time
        </title>
        <meta
          name="description"
          content="Apace Refunds For Shoppers | Apace helps you get your refund instantly so you can eliminate refund anxiety and enjoy your purchase."
        />
        <link rel="icon" href={favicon} />
      </Helmet>
      {/* <div className="block lg:hidden">
        <MobileMenu drawerVal="shoppers" />
      </div> */}
      <div className="new_home_container">
        <ThemeProvider theme={theme}>
          {/* <Container maxWidth="xl" disableGutters={true}> */}
          <Navbar />
          <HeroHeader />
          <Works />
          <Mission />
          <Merchants />
          <Question />
          <Difference />
          <Footer />
          {/* </Container> */}
        </ThemeProvider>
      </div>
    </Fragment>
  )
}

export default Home
