import React, { Fragment, useState } from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import { CircularProgress, Grid } from "@mui/material"
import closeIcon from "../../assets/new_images/close.svg"
import "./FormModal.css"
import { PrimaryButton } from "../Button/Button"
import searchIcon from "../../assets/new_images/search.svg"
import axios from "axios"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  height: "90%",
  bgcolor: "#F6F6F6",
  //   border: "2px solid #000",
  boxShadow: 24,
  overflow: "scroll",
}

const FormModal = ({ setFormState, setIsSubmitted }) => {
  let [open, setOpen] = useState(false)
  let [company, setCompany] = useState("")
  let [email, setEmail] = useState("")
  let [description, setDescription] = useState("")
  let [getCompany, setGetCompany] = useState(null)
  let [selectedCompany, setSelectedCompany] = useState([])
  let [loading, setLoading] = useState(false)
  let handleClose = () => handleClose(false)

  const getCompanies = async () => {
    try {
      if (company && company.length >= 2) {
        let response = await axios.get(
          `https://autocomplete.clearbit.com/v1/companies/suggest?query=${company}`
        )
        setGetCompany(response?.data)
      } else {
        setGetCompany(null)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const selectCompaniesFunc = item => {
    let result = null
    let filteredSel = selectedCompany.filter((sel, i) => {
      if (sel?.name === item?.name) {
        result = true
      } else {
        result = false
      }
    })
    if (!result) {
      setSelectedCompany([...selectedCompany, { ...item }])
      setGetCompany(null)
      setCompany("")
    }
  }

  const removeCompany = name => {
    const filteredCompArr = selectedCompany.filter(
      (item, i) => item.name !== name
    )
    setSelectedCompany(filteredCompArr)
  }

  const addCustomCompany = item => {
    let result = null
    let filteredSel = selectedCompany.filter((sel, i) => {
      if (sel?.name === company) {
        result = true
      } else {
        result = false
      }
    })
    if (!result) {
      setSelectedCompany([...selectedCompany, { name: company }])
      setGetCompany(null)
      setCompany("")
    }
  }

  React.useEffect(() => {
    getCompanies()
  }, [company])

  const sendFormData = async () => {
    if (selectedCompany.length === 0) {
      alert("Company is required")
    } else if (!email) {
      alert("Email is required")
    } else {
      let companyArr = []
      selectedCompany.map(item => {
        companyArr.push(item.name)
      })
      let data = { email, description, companies: companyArr }
      setLoading(true)
      try {
        let response = await axios.post(
          `https://sheet.best/api/sheets/94bb5745-9436-43d8-9658-47b84e0f779c`,
          data
        )
        setSelectedCompany([])
        setEmail("")
        setDescription("")
        setLoading(false)
        setIsSubmitted(true)
        setFormState(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }
  }

  return (
    <div>
      <Modal
        open={true}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="close_btn_container">
            <img
              src={closeIcon}
              alt="close btn"
              onClick={() => setFormState(false)}
            />
          </div>
          <div className="modal_container">
            <Grid container spacing={10} className="modal_grid_container">
              <Grid item xs={12} md={6}>
                <div className="company_selection_country">
                  <Typography variant="h5">
                    Select one or multiple retailers that you'd like to see
                    partnered with Apace
                  </Typography>
                  <div className="company_select">
                    <div className="select_input">
                      <input
                        type="text"
                        placeholder="Search Company Name..."
                        value={company}
                        onChange={e => {
                          setCompany(e.target.value)
                        }}
                      />
                      {/* <img src={searchIcon} alt="search icon" /> */}
                    </div>
                    <div className="box">
                      <div className="selection_box">
                        {getCompany ? (
                          <Fragment>
                            {getCompany?.map((item, index) => (
                              <div>
                                <div
                                  key={index}
                                  className="result"
                                  onClick={() => {
                                    selectCompaniesFunc(item)
                                  }}
                                >
                                  <img src={item?.logo} />
                                  <p>{item?.name}</p>
                                </div>
                              </div>
                            ))}
                            <div className="custom_company result">
                              {getCompany.length === 0 ? (
                                <small>No result found</small>
                              ) : null}
                              <p onClick={addCustomCompany}>
                                Click here to add custom company
                              </p>
                            </div>
                          </Fragment>
                        ) : null}
                      </div>
                    </div>
                    <div className="companies_cards">
                      <Grid container spacing={3}>
                        {selectedCompany?.map((item, index) => (
                          <Grid item xs={6} md={6} lg={4}>
                            <div className="select_card">
                              <span onClick={() => removeCompany(item?.name)}>
                                x
                              </span>
                              {item?.logo ? (
                                <img src={item?.logo} alt="amazon" />
                              ) : null}
                              <small className={item?.logo ? null : "center"}>
                                {item?.name}
                              </small>
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6} className="remove_padding">
                <div className="form_container2">
                  <input
                    type="email"
                    placeholder="Email Address"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                  <br />
                  <textarea
                    placeholder="Leave us a message"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                  />
                  <div className="form_btn_container">
                    <PrimaryButton onClick={sendFormData} disabled={loading}>
                      {loading ? (
                        <CircularProgress color="success" />
                      ) : (
                        "Send it"
                      )}
                    </PrimaryButton>
                  </div>
                  <div className="form_container_agree_text">
                    <Typography variant="body1">
                      By submitting this form you agree to our privacy policy.
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default FormModal
