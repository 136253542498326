import React from "react"
import { Typography } from "@mui/material"
import CustomAccordion from "../Accordion/Accordion"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import "./Question.css"

const Question = () => {
  const { ref, inView, entry } = useInView()
  const questionMockArr = [
    {
      title: "Is Apace a loan?",
      description: (
        <div>
          No, Apace is not a loan.
          <br />
          <br />
          Apace simply bridges the gap. The money belongs to you at the point of
          approved return; Apace simply helps to get it in your pocket faster.
          We do this by issuing a cash advance from the time of return until the
          money would theoretically hit your account (anywhere from 4-16 days)
        </div>
      ),
    },
    {
      title: "How do I utilize Apace services?",
      description:
        "Make sure to look for a retailer that has a partnership with Apace. You will have an option to utilize Apace services in the return process. Note: In order to use Apace, retailers must have an existing partnership. Apace does not provide direct to consumer servicing.",
    },
    {
      title: "Where can Apace send funds?",
      description:
        "Apace is bank/debit card agnostic meaning we can push your money to you, anytime + anywhere.",
    },
    {
      title: "Does Apace operate on weekends or holidays?",
      description:
        "Yes, Apace operates 24/7/365. Apace knows the importance of having your money when you need it, where you need it.",
    },
    {
      title: "How long will it take for me to receive my money?",
      description: "Your funds will hit your account instantly.",
    },
    {
      title: "Still have questions?",
      description:
        "We are happy to help you with any questions. Just email us at refunds@apacerefunds.com",
    },
  ]
  return (
    <motion.div
      id="faqs"
      className="question_container custom_main_container"
      ref={ref}
      initial={inView === false ? { y: 50, opacity: 0 } : {}}
      animate={inView === true ? { y: 0, opacity: 1 } : {}}
      transition={{ bounce: false, type: "spring", stiffness: 30 }}
    >
      <div className="question_header">
        <Typography variant="h3">
          Your Frequently Asked Questioned, <span>answered</span>.
        </Typography>
      </div>
      <div className="question_section">
        <CustomAccordion questionMockArr={questionMockArr} />
      </div>
    </motion.div>
  )
}

export default Question
