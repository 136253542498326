import React, { useState } from "react";
import "./Difference.css";
import { Grid, Typography, Container } from "@mui/material";
import { PrimaryButton } from "../Button/Button";
// import difference_img from "../../assets/new_images/difference_1.png"
import diff from "../../assets/new_images/diff.png";
import cloud_1 from "../../assets/new_images/cloud_1.png";
import cloud_2 from "../../assets/new_images/cloud_2.png";
import VoteNow from "../Form/VoteNow/VoteNow";
import FormModal from "../FormModal/FormModal";

const Difference = () => {
  let [formState, setFormState] = useState(false);
  let [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <div className="Difference_container" id="recommend-retailer">
      {formState ? (
        <FormModal
          setFormState={setFormState}
          setIsSubmitted={setIsSubmitted}
        />
      ) : null}
      <Container sx={{ maxWidth: "1800px" }} maxWidth={false}>
        <Grid
          container
          spacing={5}
          className="vote-now-grid Grid_container_spread_tablet custom_main_container "
        >
          <Grid item xs={12} md={6}>
            <div className="difference_content">
              <Typography variant="h3">Make a difference</Typography>
              <Typography variant="body1">
                We want your input! Let us know which of your favorite retailers
                you want to see partner with Apace. Enjoy instant refunds at the
                places you shop most!
              </Typography>
              <div className="difference_btn_container">
                {isSubmitted ? (
                  <Typography variant="body1" className="success">
                    Form Submitted Successfully
                  </Typography>
                ) : (
                  <PrimaryButton onClick={() => setFormState(!formState)}>
                    SUBMIT
                  </PrimaryButton>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="difference_img">
              <div className="cloud_1">
                <img src={cloud_1} alt="cloud" className="cloud_1" />
              </div>
              <div className="main_img">
                <img src={diff} alt="difference picture between" />
              </div>
              <div className="cloud_2">
                <img src={cloud_2} alt="cloud" className="cloud_2" />
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Difference;
