import React from "react"
import { Typography, Grid } from "@mui/material"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import card1 from "../../assets/new_images/card_1.png"
import card2 from "../../assets/new_images/card_2.png"
import card3 from "../../assets/new_images/card_3.png"
import "./Mission.css"

const Mission = () => {
  const { ref, inView, entry } = useInView()
  return (
    <div id="why-apace" className="mission_container custom_main_container">
      <motion.div
        className="mission_header"
        initial={inView === false ? { y: 50, opacity: 0 } : {}}
        animate={inView === true ? { y: 0, opacity: 1 } : {}}
        transition={{ bounce: false, type: "spring", stiffness: 30 }}
      >
        <Typography variant="h3">
          Our mission is simple. To get you <span>your</span> money refunded{" "}
          <span>instantly</span>.
        </Typography>
      </motion.div>
      <div className="mission_card_container">
        <Grid
          container
          spacing={4}
          className="Grid_container_spread_tablet edit_container_mission"
          ref={ref}
        >
          <Grid item xs={12} md={4}>
            <motion.div
              className="m_card px_resize"
              initial={inView === false ? { y: 50, opacity: 0 } : {}}
              animate={inView === true ? { y: 0, opacity: 1 } : {}}
              transition={{ bounce: false, type: "spring", stiffness: 30 }}
            >
              <img src={card1} alt="card" />
              <Typography variant="h4">Accessibility</Typography>
              <Typography variant="body1">
                Money comes and goes. Too often, waiting for a refund ties up
                your money for weeks. Apace unties that knot and gives you
                immediate control of your refund.
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={4}>
            <motion.div
              className="m_card"
              initial={inView === false ? { y: 50, opacity: 0 } : {}}
              animate={inView === true ? { y: 0, opacity: 1 } : {}}
              transition={{ bounce: false, type: "spring", stiffness: 30 }}
            >
              <img src={card2} alt="card" />
              <Typography variant="h4">Transparency</Typography>
              <Typography variant="body1">
                The Apace fee structure is simple and always the same. 1.9%{" "}
                <span style={{ fontSize: 14 }}>(for some merchants 2.5%)</span>{" "}
                of the total refund amount gets deducted. Never more. Never
                less.
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={4}>
            <motion.div
              className="m_card"
              initial={inView === false ? { y: 50, opacity: 0 } : {}}
              animate={inView === true ? { y: 0, opacity: 1 } : {}}
              transition={{ bounce: false, type: "spring", stiffness: 30 }}
            >
              <img src={card3} alt="card" />
              <Typography variant="h4">Flexibility</Typography>
              <Typography variant="body1">
                Apace makes your shopping experience flexible and stress-free.
                With Apace, your refund will be in your bank account instantly
                allowing you to freely repurchase or rebook.
              </Typography>
            </motion.div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Mission
