import React, { Fragment, useState } from "react"
import { Grid, Typography,Container } from "@mui/material"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import back_1 from "../../assets/new_images/work_sec/back_img_1.png"
import back_2 from "../../assets/new_images/work_sec/back_img_2.png"
import back_3 from "../../assets/new_images/work_sec/back_img_3.png"
import front_1 from "../../assets/new_images/work_sec/front_img_2.png"
import front_2 from "../../assets/new_images/work_sec/front_img_1.png"
import front_3 from "../../assets/new_images/work_sec/front_img_3.png"
import "./Works.css"

const Works = () => {
  let [stepState, setStepState] = useState("step1")
  const containerView = useInView()
  const step1 = useInView()
  const step2 = useInView()
  const step3 = useInView()

  return (
    <motion.div
      className="works_container"
      id="how-it-works"
      ref={containerView.ref}
      initial={containerView.inView === false ? { y: 30, opacity: 0 } : {}}
      animate={containerView.inView === true ? { y: 0, opacity: 1 } : {}}
      transition={{ bounce: false, type: "spring", stiffness: 30 }}
    >
            <Container sx={{ maxWidth:'1800px'}} maxWidth={false} >
      <Grid
        container
        spacing={3}
        className="Grid_container_spread_tablet custom_main_container"
      >
        <Grid item xs={12} md={6}>
          <div className="works_content">
            <Typography variant="h3">How it works</Typography>
            <div className="steps_container">
              <Typography
                variant="body1"
                className={stepState === "step1" ? "activeStep" : null}
                onClick={() => setStepState("step1")}
              >
                Step 1
              </Typography>
              <Typography
                variant="body1"
                className={stepState === "step2" ? "activeStep" : null}
                onClick={() => setStepState("step2")}
              >
                Step 2
              </Typography>
              <Typography
                variant="body1"
                className={stepState === "step3" ? "activeStep" : null}
                onClick={() => setStepState("step3")}
              >
                Step 3
              </Typography>
            </div>
            {stepState === "step1" ? (
              <Fragment>
                <Typography variant="h4">
                  Request an instant refund for your return.
                </Typography>
                <Typography variant="body2">
                  When returning an item to a retailer that has a partnership
                  with Apace, your retailer will send you an offer via email to
                  proceed with Apace. Accept that instant refund offer and
                  request your money instantly!
                </Typography>
                <span className="recommend-retailer">
                  <a href="#recommend-retailer" style={{ color: "grey" }}>
                    Click here
                  </a>{" "}
                  to recommend a retailer to Apace.
                </span>
              </Fragment>
            ) : null}
            {stepState === "step2" ? (
              <Fragment>
                <Typography variant="h4">
                  Provide some basic information.
                </Typography>
                <Typography variant="body1">
                  When you receive a refund offer from your retailer, fill out
                  some basic information to proceed with your refund. No credit
                  check or screening is needed.
                </Typography>
              </Fragment>
            ) : null}
            {stepState === "step3" ? (
              <Fragment>
                <Typography variant="h4">Enjoy your instant refund.</Typography>
                <Typography variant="body1">
                  And just like that, your refund money will be in your account
                  instantly!
                </Typography>
              </Fragment>
            ) : null}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          {stepState === "step1" ? (
            <Fragment>
              <div className="works_img">
                <div className="img_container">
                  <motion.img
                    animate={{ opacity: step1.inView ? 1 : 0 }}
                    initial={{ opacity: step1.inView ? 0 : 0 }}
                    transition={{
                      delay: 0,
                      duration: 1,
                    }}
                    hidden={stepState !== "step1"}
                    src={back_1}
                    alt="works image"
                  />
                  <motion.div
                    ref={step1.ref}
                    className="inner_img"
                    animate={{ scale: step1.inView ? 1 : 0 }}
                    initial={{ scale: step1.inView ? 1 : 0 }}
                    transition={{
                      type: "spring",
                      delay: 0.7,
                    }}
                  >
                    <img src={front_1} alt="upper image" />
                  </motion.div>
                </div>
              </div>
            </Fragment>
          ) : null}
          {stepState === "step2" ? (
            <Fragment>
              <div className="works_img">
                <div className="img_container">
                  <motion.img
                    animate={{ opacity: step2.inView ? 1 : 0 }}
                    initial={{ opacity: step2.inView ? 0 : 0 }}
                    transition={{
                      delay: 0,
                      duration: 1,
                    }}
                    src={back_2}
                    alt="works image"
                  />
                  <motion.div
                    ref={step2.ref}
                    className="inner_img"
                    animate={{
                      scale: step2.inView ? 1 : 0,
                    }}
                    initial={{ scale: step2.inView ? 1 : 0 }}
                    transition={{
                      type: "spring",
                      delay: 0.7,
                    }}
                  >
                    <img src={front_2} alt="upper image" />
                  </motion.div>
                </div>
              </div>
            </Fragment>
          ) : null}
          {stepState === "step3" ? (
            <Fragment>
              <div className="works_img">
                <div className="img_container">
                  <motion.img
                    animate={{ opacity: step3.inView ? 1 : 0 }}
                    initial={{ opacity: step3.inView ? 0 : 0 }}
                    transition={{
                      delay: 0,
                      duration: 1,
                    }}
                    src={back_3}
                    alt="works image"
                  />
                  <motion.div
                    ref={step3.ref}
                    className="inner_img"
                    animate={{
                      scale: step3.inView ? 1 : 0,
                    }}
                    initial={{ scale: step3.inView ? 1 : 0 }}
                    transition={{
                      type: "spring",
                      delay: 0.7,
                    }}
                  >
                    <img src={front_3} alt="upper image" />
                  </motion.div>
                </div>
              </div>
            </Fragment>
          ) : null}
        </Grid>
      </Grid>
      </Container>
    </motion.div>
  )
}

export default Works
